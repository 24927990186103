@import 'styles/components/loader';

.contentFixed{
  display: none;
}

#maincontent{
  max-width: unset;
  padding: 0;
  margin: 0;
}

.plain-btn{
  border: 2px solid $active-container-background;
  color: $base-background !important;
  background-color: $active-container-background;
  padding: 17px 60px;
  border-radius: $btn-border-radius;
  margin-top: .5rem;
  font-weight: 600;
  font-size: 1.2rem !important;
  text-decoration: none;
  width: fit-content;
  text-align: center;

  &:hover{
    cursor: pointer;
  }
}

.secondary-btn{
  border: 2px solid $active-container-background !important;
  color: $active-container-background !important;
  background-color: $provider-tile;
  padding: 15px 30px;
  border-radius: $btn-border-radius;
  margin-top: .5rem;
  font-weight: 600;
  font-size: 1.2rem !important;

  &:hover{
    cursor: pointer;
  }
}

.third-btn{
  border: none;
  color: $link-color;
  background-color: $shade-background;
  padding: 10px 25px;
  border-radius: $btn-border-radius;
  margin-top: .5rem;
  font-weight: 600;
  font-size: 1.2rem !important;
  width: fit-content;
  text-align: center;

  &:hover{
    cursor: pointer;
  }
}

.intro-container {
    position: relative;
    // max-width: 800px;
    margin: 0 auto;
    background-color: $base-background;
    margin-top: -1px;
  }
  
  .carousel {
    display: flex;
    overflow: hidden;
  }

 
  @keyframes showElement {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      display: flex;
    }
  }
  
  .hiddenintro {
    animation: showElement 0.3s forwards;
    display: none;
    opacity: 0;
  }
  
  .intro {
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    // opacity: 0;
    // transition: opacity 0.3s, display 0s 0.3s;

    .intro-img{
      width: 45%;
      box-sizing: border-box;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }  
    }

    .intro-text{
      width: 55%;
      text-align: left;
      box-sizing: border-box;
      padding: 5rem 6rem;
      position: relative;
      padding-left: 7%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;

      h1{
        font-weight: 700;
        margin-bottom: 2rem;
        color: $base-color;
        font-size: 4rem;
        line-height: normal;
        
      }

      h2{
        font-weight: 700;
        margin-bottom: 2rem;
        color: $base-color;
        font-size: 2.5rem;
        line-height: 3rem;
      }

      p{
        font-weight: 600;
        font-size: 1.25rem;
        line-height: normal;
        color: $base-color;
        margin-bottom: 2rem;
        max-width: 95%;
      }

      a{
        padding: 1rem 2rem;
        background: $base-color;
        border-radius: 7px;
        color: $base-background;
        font-size: 1.25rem;

        &:hover{
          text-decoration: none;
          background:$base-background;
          color: $base-color;
          border: 2px solid $base-color;
        }

        &:focus{
          text-decoration: underline;
          background:$base-background;
          color: $base-color !important;
          border: 2px solid $base-color;
        }



      }

    }

    
  }
  
  
  .carousel-nav {
    position: absolute;
    bottom: 3rem;
    left: 57%;
  }
  
  .prev-btn{
    font-size: 20px;
    cursor: pointer;
    margin: 0 10px;
    background-color: $base-background;
    width: 26px;
    border-radius: 100%;
    height: 26px;
    position: absolute;
    left: 40px;
    top: 50%;

    .fa-angle-left{
      margin-left: 7px;
      margin-top: 4px;
    }
  }

  .next-btn {
    font-size: 20px;
    cursor: pointer;
    margin: 0 10px;
    background-color: $base-background;
    width: 26px;
    border-radius: 100%;
    height: 26px;
    position: absolute;
    right: 40px;
    top: 50%;

    .fa-angle-right{
      margin-left: 9px;
      margin-top: 3px;
    }
  }
  
  .dots {
    display: flex;
    align-items: center;
  }
  
  .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: $base-background;
    margin: 0 5px;
    cursor: pointer;
  }

  .activeintro{
    opacity: 1;
    display: flex;
    // transition: opacity 0.3s;
  }

  .activeDot{
    background-color: $base-color;
  }

  .connections-container {
    position: relative;
    margin: 0 auto;
    background-color: $base-background;
    margin-top: -1px;
    padding: 6rem 0;
    
  .connections {
    display: flex;
    overflow: hidden;

    .connection {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      box-sizing: border-box;

      .connection-img{
        width: 50%;
        box-sizing: border-box;
        position: relative;
        display: flex;
        align-items: center;
  
        img {
          max-height: 500px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }  
      }
  
      .connection-text{
        width: 50%;
        text-align: left;
        box-sizing: border-box;
        padding: 7rem;
        position: relative;
        padding-left: 7%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
  
        h1{
          font-weight: 700;
          margin-bottom: 2rem;
          color: $base-color;
          font-size: 2.5rem;
          line-height: normal;
          
        }
  
        h2{
          font-weight: 700;
          margin-bottom: 2rem;
          color: $base-color;
          font-size: 2.5rem;
          line-height: 3rem;
        }
  
        p{
          font-weight: 600;
          font-size: 1.25rem;
          line-height: normal;
          color: $base-color;
          margin-bottom: 2rem;
        }
  
        a{
          padding: 1rem 2rem;
          background: $base-color;
          border-radius: 7px;
          color: $base-background;
          font-size: 1.25rem;
  
          &:hover{
            text-decoration: none;
            background:$base-background;
            color: $base-color;
          }
        }
  
      }
  
    }
    }
  }


  .looking-for-container{
    margin-left: 14%;
    margin-right: 14%;

    h2{
        text-align: center;
        margin: 3rem 0 0;
        font-weight: 700;
        font-size: 2.5rem;
    }

    .looking-for-content{
        display: flex;
        /* flex-direction: row; */
        justify-content: space-between;
        margin-bottom: 5rem;
        flex-wrap: wrap;
        overflow: hidden;
        padding-top: 75px;

        .looking-for-tile{
          text-align: center;
          width: 30%;
          min-width: 250px;
          justify-content: space-between;
          display: flex;
          flex-direction: column;
          align-items: center;
          
            h3 {
              font-weight: 700;
              padding: 1rem;
              font-size: 1.25rem;
            }

            p {
              padding: 0 1rem;
              margin-bottom: 1rem;
              font-size: 1rem;
              line-height: normal;
            }

            img{
              width: auto;
              max-width: 100%;
            }

            a{
              &:hover{
                background-color: $base-background !important;
                color: $active-container-background !important;
                border: 2px solid $active-container-background;
              }
            }

        }
    }

    .looking-for-content-second{
        display: flex;
        /* flex-direction: row; */
        justify-content: space-between;
        margin-bottom: 5rem;
        flex-wrap: wrap;

        .looking-for-tile{
          text-align: center;
          width: 30%;
          min-width: 250px;
          justify-content: space-between;
          display: flex;
          flex-direction: column;
          align-items: center;

            h3 {
              font-weight: 700;
              padding: 1rem;
              font-size: 1.25rem;
            }

            p {
              padding: 0 1rem;
              margin-bottom: 3rem;
              font-size: 1rem;
              line-height: normal;
            }

            img{
              width: auto;
              max-width: 100%;
            }
        }

        .testimonial-tile{
          max-width: 50%;

          div{
            align-items: center;
            height: 100%;
            justify-content: space-between;
          }
        }
      }
  }

  .available-container{
    background-color: $base-background;
    padding-left: 14%;
    padding-right: 14%;

    h2{
      text-align: center;
      padding: 4rem 0 3rem;
      font-weight: 700;
      font-size: 2.5rem;
    }
    
    .available-content{
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-bottom: 5rem;
      column-gap: 5%;

      .available-tile{
        width: 40%;
        display: flex;
        align-items: center;
        border-radius: 10px;
        justify-content: center;

        .plain-btn{
          background-color: $base-background;
          color: $active-container-background;
          display: block;
        }

        .available-tile-text{
          background-color: $provider-tile;
          color: $active-container-background;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          border-radius: 10px;
          padding: 2rem;
          height: 100%;
          box-sizing: border-box;
          margin: 0;

          h3{
            font-size: 3.5rem;
            margin: 0;
            font-weight: 700;
          }

          p{
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 4rem;
            margin-top: 1rem;
          }

          div{
            margin-top: 1rem;
          }

        }

        .available-tile-image{
          max-width: 40%;
          box-sizing: border-box;
          height: 250px;

          img{
            width: 100%;
            height: 250px;
            box-sizing: border-box;
            // margin-top: 1px;
            object-fit: cover;
            border-radius: 0 10px 10px 0;
          }
        }
      }

    }
  }
  
  .red-btn-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding-left: 0 !important;
 
    a {
       padding: 0.6rem 4rem;
       background-color: rgb(207 16 44);
       color: white;
       text-decoration: none;
       font-size: 0.9rem;
 
       &:hover{
          color: white;
       }
    }
 }
 

  // @media screen and (min-width: 1024px) and (max-width: 1600px){

  //   .intro {
  //     .intro-text{
  //       padding: 5rem 6rem;
  //     }
  //   }

  // }

  @media screen and (min-width: 768px) and (max-width: 1024px){

    .intro {
      flex-direction: column;

      .intro-img{
        width: 140%;

        img{
          max-height: 600px;
        }
      }

      .intro-text{
        width: 100%;
        padding: 4rem;
      }
    }

    .connections-container {
      padding: 4rem 0;

      .connection {
        flex-direction: column-reverse !important;

        .connection-img{
          width: 100% !important;
        }

        .connection-text{
          width: 100% !important;
          padding: 4rem !important;
        }
      }

    }

    .carousel-nav{
      left: 42%;
    }

    .next-btn, .prev-btn{
      display: none;
    }

    .looking-for-container{
      .looking-for-content{
        // flex-direction: column;
        // align-items: center;
        row-gap: 3rem;
  
        .looking-for-tile{
          // width: 100%;
          img{
            width: auto;
          }
        }
      }
    }

    .available-container{
      .available-content{
        flex-direction: column;
        row-gap: 2rem;
  
        .available-tile{
          width: 100%;
          justify-content: left;
        }

        .sign-up-tile{
          width: 100%;
          padding: 2rem;
        }
      }
    }

    .looking-for-content-second{
      gap: 3rem;
      .testimonial-tile{
        max-width: 100% !important;
      }
    }

    .plain-btn{
      padding: 15px 30px;
    }
    

    .marketplace-container-btns{
      display: flex !important;
      gap: 2rem !important;
   }

   .marketplace-container{
      border-radius: 0;
      padding: 2rem 3rem !important;
   }

   .available-container{
      padding-left: 5%;
      padding-right: 5%;
   }

   .marketplace-container{
      display: none !important;
   }

   // Animation
  .hidden-tile{
    transform: translateX(-100%) !important;
  }

  .show-tile{
    transform: translateX(0) !important;
    transition: transform 1.5s ease !important;
  }

  }

  @media screen and (min-width: 320px) and (max-width: 767px){

    .marketplace-container{
      display: none !important;
   }

    .looking-for-content-second{
      gap: 3rem;
      .testimonial-tile{
        max-width: 100% !important;
      }
    }

    .intro {
      flex-direction: column;

      .intro-img{
        width: 140%;
      }

      .intro-text{
        width: 100%;
        padding: 4rem 2rem;
      }
    }

    .connections-container {
        padding: 4rem 0 0;

      .connection {
        flex-direction: column !important;

        .connection-img{
          width: 100% !important;
        }

        .connection-text{
          width: 100% !important;
          padding: 4rem 4rem 1rem 4rem !important;
        }
      }
    }

    .carousel-nav{
      left: 42%;
    }

    .next-btn, .prev-btn{
      display: none;
    }

    
    .looking-for-container{
      .looking-for-content{
        flex-direction: column;
        align-items: center;
        row-gap: 3rem;
  
        .looking-for-tile{
          width: 100%;
          img{
            width: auto;
          }
        }
      }
    }
    
    .available-container{
      .available-content{
        flex-direction: column;
        row-gap: 2rem;
  
        .available-tile{
          width: 100%;
          flex-direction: column;

          .available-tile-text{
            border-radius: 10px;
            width: 100%;
          }

          .available-tile-image{
            width: 100%;
            max-width: 100%;
            margin-top: 0;
            padding: 0;

            img{
              margin: 0;
              border-radius: 0 0 10px 10px;
            }
          }
        }

        .sign-up-tile{
          width: 100%;
          padding: 2rem;
        }
      }
    }

    .red-btn-container li{
      width: 100%;
      text-align: center;
    }

    .plain-btn{
      padding: 15px 30px;
    }

    .marketplace-container-btns{
      display: flex !important;
      gap: 2rem !important;
   }

   .marketplace-container{
      border-radius: 0;
      padding: 2rem 3rem !important;
      margin-top: 7rem !important;
      margin-bottom: 2rem !important;
   }

   .available-container{
      padding-left: 5%;
      padding-right: 5%;
   }

   .map-container{
      display: none !important;
   }

   .hidden-tile{
      transform: translateX(-100%) !important;
    }

    .show-tile{
      transform: translateX(0) !important;
      transition: transform 1.5s ease !important;
    }

  }
  

  .sign-up-tile {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: $hightlight-background-color;
    border-radius: 10px;
    padding: 4rem 3rem;
    background-image: url('/htmlCmsPages/images/signup.png');
    background-repeat: no-repeat, no-repeat;
    background-position-y: top;
    background-position-x: right;

    h3 {
      font-weight: 700;
      margin-bottom: 2rem;
      font-size: 2.5rem;
    }

    p{
      font-weight: 700;
      margin-bottom: 2rem;
    }
  }

  .testimonial-tile {

    display: flex;

    img {
      border-radius: 10px;
      height: max-content;
    }

    div {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2rem;
      text-align: center;

      h3 {
        font-weight: bold;
        font-size: 1.2rem;
      }

      .h4 {
        font-weight: bold;
        margin-bottom: 1rem;
      }

      p {
        font-size: 0.9rem;
      }

    }

    
  }

 
// Map
.map-container{
  width: 100%;
}

.marketplace-container{
  background: $map-container;
  border-radius: 3rem;
  padding-top: 1rem;
  margin-top: 10rem;
  margin-bottom: 4rem;

  h2{
     text-align: center;
     margin: 3rem 0 3rem;
     font-weight: 700;
     font-size: 2.5rem;
  }
  
}

#mapsection {
  fill: #57bce7;
  stroke-width: 0px;
  position: relative;
}


path {
  fill: #57bce7;
}

.active-region{
  fill: #57bce7;
}

.inactive-region, .map path{
  fill: #57bce754;
  transition: fill 0.4s;
}

.region-name path{
  fill: #000000;
  transition: fill 0.4s;
}

.active-name {
  fill: #000;
  transition: fill .4s;
  transform-box: border-box;
  transform: scale(1.1);
}

.inactive-name {
  fill: #00000054 !important;
  transition: fill .4s;
}

@keyframes drawLine {
  0% {
      stroke-dashoffset: 1000; /* Start with fully hidden line */
  }
  50% {
      stroke-dashoffset: 500; /* Draw the line halfway */
  }
  100% {
      stroke-dashoffset: 0; /* Keep the line halfway */
  }
}


.pointer path, .region-contour path, .name-contour path{
  fill: transparent;
}

.active-pointer path {
  position: absolute;
  stroke-width: 2 !important;
  stroke-dasharray: 1000; /* Length of the line */
  stroke-dashoffset: 1000; /* Initially hide the line */
  animation: drawLine 2s forwards; /* Animate the line drawing */
}

.marketplace-container-btns{
  display: none;
}

.map-container{
  display: block;
}

 // Animation

// Intro section - animation
.hidden-intro-text{
  opacity: 0;
 //  filter: blur(5px);
  transform: translateY(100%);
  transition: all 1s;
}

.show-intro-text{
  opacity: 1;
 //  filter: blur(0);
  transform: translateY(0);
}

.hidden-intro-text:first-child() {
  transition-delay: 0;
}

.hidden-intro-text:nth-child(2) {
  transition-delay: 200ms;
}

.hidden-intro-text:nth-child(3) {
  transition-delay: 400ms;
}

.hidden-intro-text:nth-child(4) {
  transition-delay: 600ms;
}


// Intro section - animation
.hidden-connections{
  opacity: 0;
 //  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 1s;
}

.hidden-connections-right{
  transform: translateX(100%) !important;
}

.show-connections{
  opacity: 1;
 //  filter: blur(0);
  transform: translateX(0) !important;
}

.hidden-connections:first-child() {
  transition-delay: 200ms;
}

.hidden-connections:nth-child(2) {
  transition-delay: 400ms;
}

.hidden-connections:nth-child(3) {
  transition-delay: 600ms;
}

.hidden-connections:nth-child(4) {
  transition-delay: 1s;
}

// What are you looking for section - animation
.hidden-tile{
  opacity: 0;
 //  filter: blur(5px);
  transform: translateY(100%);
  transition: all 1s;
  &:hover{
   transform: translateY(0);
 }
}

.show-tile{
  opacity: 1;
 //  filter: blur(0);
  transform: translateY(75px);
  transition: transform 0.5s ease;
}

.disabled-hover{
  pointer-events: none;
}


#DBT-home-3{
 //  transform: translateY(0) !important;
  &.show-tile{
   opacity: 1;
   // filter: blur(0);
   transform: translateY(0px);
   transition: transform 0.5s ease;
 }
}

.delay-animation{
 transition-delay: calc(300ms * var(--order));
}




// Animation contrast
.alt-contrast{
 .region-name path{
   fill: #ff0;;
 }
 .active-pointer path {
   stroke: #ff0;
 }
 .available-tile-text{
   background-color: #3a3a3a !important;
 }

 .sign-up-tile{
   background-image: none;
   background-color: #3a3a3a !important;
 }

 .intro-text{
   a{
     &:focus{
       color: #00e1e8 !important;
     }
   }
 }
}