// Background colors
$base-color: #07073f;
$base-background: white;
$hightlight-color: #cf102c;
$hightlight-background-color: #edf0f3;
$hover-background-color: #eaf7f3;
$border-color: #f1f5f6;
$input-border-color: #a1a7a8;
$shade-background: #edf0f3;
$link-color: #070707;
$active-container-background: #000000;
$provider-tile: #8ce2d0;

$map-container: #effafd;


$tick-unseen: #575757;
$tick-seen: #177fe8;

$vacancy-back: #dbf1eb;
$messages-back: #dbf1eb;
$shortlist-back: #ececec;

$btn-border-radius: 0px;

// Font colors
$base-font: #07073f;
$font-family: 'Noto Sans', sans-serif !important;

$homeMaxWidth: 1200px;
$paPadding: 2rem;

$progressBarColor: #e7f2ef;


@import 'styles/home';


.paMessages {
   // border-top: 2px solid $border-color;
   min-height: 600px;
}